<template>
    <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel" :hide-cancel="true" :draggable="true" width="380px">
        <template #title>
            登录体验更多功能
        </template>
        <div>
            <login-user v-if="visible"/>
        </div>
        <template #footer>
            <div class="footer">V1.0.0</div>
        </template>
    </a-modal>
</template>
<script>
import loginUser from '@/layout/LoginUser'
export default {
    components: {
        loginUser
    },
    props: {
        visible: {
            default: false
        }
    }
}
</script>
<style scoped>
.footer{
    color: #999;
    font-size:12px;
}
</style>
  