
<template>
  <div>
    <div class="login-content">
      <h2>登录</h2>

      <a-form ref="formRef" :model="form" layout="vertical">
        <a-form-item field="name" :rules="rules">
          <a-input v-model="form.name" size="large" placeholder="输入邮箱" />
        </a-form-item>
        <a-form-item field="post">
          <a-input v-model="form.post" :max-length="6" allow-clear show-word-limit size="large" placeholder="验证码">
            <template #append>
              <div :style="{ fontSize: '12px' }">获取验证码</div>
            </template></a-input>
        </a-form-item>
        <a-form-item field="isRead">
          <a-tooltip position="lt" :popup-visible="false">
            <template #content>
              请阅读并勾选协议
            </template>
            <a-checkbox v-model="form.isRead">
              <div class="login-tips"> 我已阅读并同意 <a href="">服务协议、隐私政策</a></div>
            </a-checkbox>

          </a-tooltip>

        </a-form-item>
        <a-form-item>
          <a-button type="primary" size="large" long @click="handleClick">登录</a-button>
        </a-form-item>
      </a-form>
      <a-divider orientation="center">其它登录方式</a-divider>
      <a-space wrap size="large" align="center">
        <div>
          <svg t="1672126195870" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="1989" width="30" height="30">
            <path
              d="M579.584 511.5904c-11.6736 0-23.381333 10.820267-23.381333 24.1664 0 10.8544 11.6736 21.7088 23.381333 21.7088 17.5104 0 30.037333-10.8544 30.037333-21.7088 0-13.312-12.526933-24.1664-30.037333-24.1664z m-76.765867-112.64c18.363733 0 30.037333-11.6736 30.037334-29.184 0-18.363733-11.6736-29.184-30.037334-29.184-17.5104 0-34.2016 10.820267-34.2016 29.184 0 17.5104 16.6912 29.184 34.2016 29.184zM512 0C229.239467 0 0 229.239467 0 512c0 282.760533 229.239467 512 512 512 282.760533 0 512-229.239467 512-512 0-282.760533-229.239467-512-512-512z m-96.768 641.706667c-30.856533 0-53.384533-4.983467-82.602667-13.312l-84.241066 42.530133 24.234666-71.748267c-59.255467-41.710933-94.3104-94.276267-94.3104-158.5152 0-113.425067 106.8032-200.192 236.919467-200.192 115.131733 0 217.736533 68.4032 237.738667 165.205334a115.643733 115.643733 0 0 0-22.528-2.525867c-113.425067 0-201.045333 85.0944-201.045334 187.6992 0 17.5104 2.525867 33.348267 6.690134 50.039467a175.342933 175.342933 0 0 1-20.821334 0.8192z m347.8528 81.749333l16.6912 60.074667-63.3856-35.84c-24.200533 4.983467-47.547733 12.4928-71.748267 12.4928-111.786667 0-200.226133-76.765867-200.226133-171.861334s88.439467-171.8272 200.226133-171.8272c105.949867 0 201.045333 76.731733 201.045334 171.8272 0 53.384533-35.874133 100.932267-82.602667 135.168zM337.646933 340.5824c-17.5104 0-35.84 10.820267-35.84 29.184 0 17.5104 18.3296 29.218133 35.84 29.218133 16.725333 0 30.037333-11.707733 30.037334-29.218133 0-18.363733-13.312-29.184-30.037334-29.184z m372.906667 171.008c-12.526933 0-23.381333 10.820267-23.381333 24.1664 0 10.8544 10.8544 21.7088 23.381333 21.7088 16.657067 0 29.184-10.8544 29.184-21.7088 0-13.312-12.526933-24.1664-29.184-24.1664z"
              fill="#3AC275" p-id="1990"></path>
          </svg>
        </div>
        <div>
          <svg t="1672126274338" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="6387" width="30" height="30">
            <path
              d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m210.385455 641.396364c-7.447273 9.309091-26.996364-1.861818-41.89091-32.581819-3.723636 13.963636-13.032727 36.305455-34.443636 64.232728 35.374545 8.378182 44.683636 42.821818 33.512727 61.44-8.378182 13.032727-26.996364 24.203636-59.578181 24.203636-58.647273 0-83.781818-15.825455-95.883637-26.996364-1.861818-2.792727-5.585455-3.723636-10.24-3.723636-4.654545 0-7.447273 0.930909-10.24 3.723636-11.170909 11.170909-37.236364 26.996364-95.883636 26.996364-32.581818 0-52.130909-11.170909-59.578182-24.203636-12.101818-18.618182-1.861818-53.061818 33.512727-61.44-20.48-27.927273-29.789091-50.269091-34.443636-64.232728-13.963636 30.72-34.443636 42.821818-41.890909 32.581819-5.585455-8.378182-8.378182-26.065455-7.447273-38.167273 3.723636-46.545455 34.443636-85.643636 53.061818-106.123636-2.792727-5.585455-8.378182-40.029091 14.894546-63.301819v-1.861818c0-92.16 65.163636-158.254545 148.014545-158.254545 81.92 0 148.014545 66.094545 148.014546 158.254545v1.861818c23.272727 23.272727 17.687273 57.716364 14.894545 63.301819 17.687273 20.48 49.338182 59.578182 53.061818 106.123636 0.930909 12.101818-0.930909 29.789091-7.447272 38.167273z"
              fill="#30A5DD" p-id="6388"></path>
          </svg>
        </div>
      </a-space>
    </div>
    <div class="account-center-qrcode"></div>
  </div>

</template>
  
<script>
import { ref, reactive } from 'vue';

export default {
  setup() {
    const formRef = ref()
    const form = reactive({
      name: '',
      post: '',
      isRead: false,
    })
    const rules = [{
      validator: (value, cb) => {
        return new Promise(resolve => {
          window.setTimeout(() => {
            if (value !== 'admin') {
              cb('name must be admin')
            }
            resolve()
          }, 2000)
        })
      }
    }];
    const handleClick = () => {
      formRef.value.setFields({
        name: {
          status: 'error',
          message: '请输入邮箱'
        },
        post: {
          status: 'error',
          message: '请输入验证码'
        }
      })
    }

    return {
      formRef,
      form,
      rules,
      handleClick
    }
  },
}
</script>
<style scoped>
.login-content {

  height: 400px;
  overflow: hidden;

}

.login-tips {
  font-size: 12px;
  color: #999999;
}

.login-tips a {
  text-decoration: none;
  color: #2a90d7;
}
</style>