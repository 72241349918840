<template>
  <div>
    <div id="basic-demo">
      <nav>
        <nav-tools />
      </nav>
      <router-view />
      <nav>
        <nav-footer />
      </nav>
    </div>
    <!-- <div class="float">

      <a-popover position="left">
        <div class="list-item">
          <svg t="1675996889210" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="8743" width="40" height="40">
            <path
              d="M768.43 749.42c-108.96-86.85-138.96-44.21-187.91 4.73-34.74 34.74-121.59-37.91-195.8-112.12-75.78-75.78-146.86-162.63-112.12-195.8 48.91-48.98 91.55-78.98 4.7-187.94-86.85-108.95-145.26-25.27-192.64 22.11-55.28 55.28-3.17 260.55 198.97 462.69s407.41 254.25 462.69 198.97c47.35-47.37 131.07-105.78 22.11-192.64z m0 0"
              fill="#A8D2FA" p-id="8744"></path>
            <path
              d="M701.47 61.28c-142.78 0-258.53 93.69-258.53 209.27 0 92.27 73.87 170.44 176.27 198.27-0.03 26.13-6.01 56.62-28.83 78.7 0 0 62.88-17.05 114.34-67.83C845.99 478.26 960 385.24 960 270.55c0-115.58-115.75-209.27-258.53-209.27z m54.64 295.86H602.38c-14.66 0-26.66-12-26.66-26.66s12-26.66 26.66-26.66h153.73c14.66 0 26.66 12 26.66 26.66 0 14.67-12 26.66-26.66 26.66z m45.85-112.05H600.98c-14.66 0-26.66-12-26.66-26.66s12-26.66 26.66-26.66h200.99c14.66 0 26.66 12 26.66 26.66-0.01 14.66-12.01 26.66-26.67 26.66z"
              fill="#669DF8" p-id="8745"></path>
          </svg>
          <div class="mt-5">联系我</div>
        </div>
        <template #content>
          联系我咨询视频创作/共创,<br>网站建设，小程序开发,<br>品牌形象推广等
        </template>

      </a-popover>

      <div class="list-item">
        <svg t="1675997466949" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="18925" width="40" height="40">
          <path
            d="M376.8 558.4c10.8-6.7 12.4-20.6 3-29.2-70.5-65.3-106.1-175.3-63.9-275.8-66.3-6.6-137.1 15.9-169.7 57.5-63 80.3-34.6 177.6 31.2 232 7.6 6.2 6.3 16.2-2.5 21.1C78.1 617.6 65.4 733 64.6 783c-0.2 13.4 11.3 24 26.3 24.1 45.4 0.2 98.4 0.1 152.9 0 8.9-78.7 38.7-190.1 133-248.7z"
            fill="#00A9DC" p-id="18926"></path>
          <path
            d="M856.6 565.7c-8.2-5-9.7-15-2-20.5 49.1-34.7 106.6-140.2 32.7-234.4-37.9-48.3-105.3-64.4-167.1-57.1 49.3 117.3-12.3 236.4-66 278.9-9.6 7.6-7.7 21.5 2.4 28.4 94.3 64.7 118.8 154.6 124.7 245.9 53.9-0.1 106.3-0.2 150.9-0.1 14.9 0 26.6-10.7 26.5-24-0.4-78.7-10.5-161-102.1-217.1z"
            fill="#FDBA3E" p-id="18927"></path>
          <path
            d="M476.9 806.8c14.9 0 26.6-10.7 26.5-24-0.6-78.7-10.7-161-102.3-217.1-8.2-5-9.7-15-2-20.5 49.1-34.7 106.6-140.2 32.7-234.4-27.5-35.1-70.7-53-116-57.5-42.2 100.6-6.6 210.6 63.9 275.8 9.3 8.7 7.8 22.5-3 29.2-94.3 58.6-124 170-133 248.7 81.4-0.1 166-0.3 233.2-0.2z"
            fill="#8F3694" p-id="18928"></path>
          <path
            d="M656.7 561c-10.1-6.9-12-20.8-2.4-28.4 53.7-42.5 115.2-161.6 66-278.9-48.9 5.8-94.3 26.2-118.5 57.1-63 80.3-34.6 177.6 31.2 232 7.6 6.2 6.3 16.2-2.5 21.1-97 53.7-109.7 169.1-110.4 219.1-0.2 13.4 11.3 24 26.3 24.1 68.1 0.3 153.4 0 235-0.1-5.9-91.3-30.4-181.2-124.7-246z"
            fill="#FF7C20" p-id="18929"></path>
          <path
            d="M546.4 807c-15-0.1-26.5-10.7-26.3-24.1 0.7-50 13.5-165.4 110.3-219.1 8.7-4.8 10-14.8 2.5-21.1-65.8-54.3-94.2-151.7-31.2-232 24.3-30.9 69.6-51.3 118.5-57.1-6.5-15.5-14.8-31-25.5-46.3-83.9-120-285.5-97.1-353.5 0-10.5 14.9-18.7 30.3-25.3 45.9 45.3 4.5 88.4 22.4 116 57.5 73.9 94.2 16.4 199.7-32.7 234.4-7.8 5.5-6.2 15.5 2 20.5 91.6 56.1 101.7 138.4 102.3 217.1 0.1 13.3-11.6 24-26.5 24-67.3-0.2-151.8 0.1-233.1 0.3-2.4 21.4-3.4 40.5-3.6 55.3-0.2 18.5 14 33.3 32.6 33.4 140 0.6 338.7-0.6 477.7-0.3 18.5 0 32.9-14.8 32.8-33.3-0.1-18.3-0.6-36.8-1.8-55.2-81.8 0.2-167.1 0.4-235.2 0.1z"
            fill="#FB536C" p-id="18930"></path>
        </svg>
        <div class="mt-5">合作共创</div>

      </div>
      <a-popover position="left">
        <div class="list-item">
          <svg t="1675997233690" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="9400" width="40" height="40">
            <path
              d="M512 1024C76.96384 1024 0 947.022507 0 512S76.96384 0 512 0 1024 76.96384 1024 512 947.022507 1024 512 1024z m0-998.4C98.727253 25.6 25.6 98.7136 25.6 512S98.727253 998.4 512 998.4 998.4 925.272747 998.4 512 925.272747 25.6 512 25.6z"
              fill="#C1D4FC" p-id="9401"></path>
            <path
              d="M279.893333 785.066667c-101.034667 0-177.793707-5.270187-236.05248-21.149014C30.26944 698.69568 25.6 616.174933 25.6 512 25.6 98.7136 98.727253 25.6 512 25.6c31.402667 0 60.648107 0.49152 88.255147 1.447253C671.45728 83.367253 689.493333 187.665067 689.493333 375.466667c0 348.023467-61.576533 409.6-409.6 409.6z"
              fill="#4D7DDD" opacity=".1" p-id="9402"></path>
            <path
              d="M811.362987 510.948693L571.378347 764.586667h-163.84l-101.512534-115.165867a27.306667 27.306667 0 0 1 0-38.611627l302.598827-302.598826a27.306667 27.306667 0 0 1 38.611627 0l164.12672 164.113066a27.306667 27.306667 0 0 1 0 38.62528z"
              fill="#C1D4FC" p-id="9403"></path>
            <path
              d="M832.047787 754.333013l-226.34496-0.955733 240.134826-235.342507a36.00384 36.00384 0 0 0 0-50.885973L618.687147 240.298667a36.072107 36.072107 0 0 0-50.926934 0l-355.45088 354.986666a36.017493 36.017493 0 0 0 0 50.885974l109.909334 109.745493-104.434347-1.59744A26.651307 26.651307 0 0 0 191.146667 780.970667v-2.00704A26.637653 26.637653 0 0 0 217.797973 805.546667h614.263467a26.651307 26.651307 0 0 0 26.66496-26.637654v2.061654a26.651307 26.651307 0 0 0-26.66496-26.637654z m-442.12224 24.890027l-40.250027-40.195413-115.152213-115.070294a4.642133 4.642133 0 0 1 0-6.539946l355.423573-354.986667a4.642133 4.642133 0 0 1 6.5536 0L823.637333 489.335467a4.614827 4.614827 0 0 1 0 6.51264L580.266667 739.027627l-40.250027 40.195413H389.925547z m204.663466-478.508373a17.380693 17.380693 0 0 0-19.278506 15.018666L542.692693 574.395733l-255.87712 28.822187a17.24416 17.24416 0 0 0 1.92512 34.379093 16.75264 16.75264 0 0 0 1.952427-0.109226l269.380267-30.35136a17.24416 17.24416 0 0 0 15.19616-15.018667l34.297173-272.1792a17.257813 17.257813 0 0 0-15.018667-19.278507z m0 0"
              fill="#4D7DDD" p-id="9404"></path>
          </svg>
          <div class="mt-5">网站投稿</div>

        </div>
        <template #content>
          投稿有机会在本网站展示
        </template>
      </a-popover>

      <div class="list-item">
        <svg t="1675997377389" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="16663" width="40" height="40">
          <path
            d="M629.972756 165.563444a73.669839 73.669839 0 0 0 13.244016 18.210522 27.315783 27.315783 0 0 0 19.452148 7.449759h154.375561v-16.55502a64.150702 64.150702 0 0 0-2.069377-15.313394 54.631566 54.631566 0 0 0-11.174639-19.866024 271.088452 271.088452 0 0 0-25.660281-28.557409c-11.174638-11.174638-26.074156-25.246405-45.112429-41.38755S701.159342 41.400794 690.812454 33.123284s-21.521526-16.141144-29.38516-21.521526a72.842088 72.842088 0 0 0-20.693775-9.519136 82.7751 82.7751 0 0 0-17.796647 0h-2.069377V124.175894a65.392329 65.392329 0 0 0 2.897128 17.796646 77.808594 77.808594 0 0 0 7.449759 20.693775z"
            fill="#4E8CEE" p-id="16664"></path>
          <path
            d="M663.496671 255.374427a91.880361 91.880361 0 0 1-50.078935-12.83014 109.263132 109.263132 0 0 1-34.351667-31.454538 140.303794 140.303794 0 0 1-19.452148-41.38755 162.239196 162.239196 0 0 1-6.208133-41.38755V0.013244H152.774305a88.983232 88.983232 0 0 0-47.595683 12.83014 168.447328 168.447328 0 0 0-39.318172 31.454538 153.54781 153.54781 0 0 0-27.315783 41.38755A115.471264 115.471264 0 0 0 28.611655 131.625653V827.764243a97.260742 97.260742 0 0 0 13.657891 49.251184 179.208091 179.208091 0 0 0 33.937791 41.38755 191.624356 191.624356 0 0 0 44.284679 29.385161 102.641124 102.641124 0 0 0 41.38755 11.174638h289.712849l203.626746-206.937749a59.598072 59.598072 0 0 1-38.490421 12.416265H224.788642a62.4952 62.4952 0 0 1-45.940181-18.624398A59.184196 59.184196 0 0 1 159.810188 703.601593a60.839698 60.839698 0 0 1 19.038273-45.526305 61.253574 61.253574 0 0 1 45.940181-19.038273h393.5956a63.736827 63.736827 0 0 1 46.767931 19.038273 61.253574 61.253574 0 0 1 19.452149 45.526305 60.011947 60.011947 0 0 1-11.588514 35.593293l144.028673-145.2703V255.374427z m0 237.150661a66.22008 66.22008 0 0 1-46.767931 18.210522H224.788642a63.736827 63.736827 0 0 1-45.940181-18.210522 60.011947 60.011947 0 0 1-19.038273-45.112429 61.667449 61.667449 0 0 1 19.038273-45.526305 62.4952 62.4952 0 0 1 45.940181-18.624397h393.5956a64.978453 64.978453 0 0 1 46.767931 18.624397 61.253574 61.253574 0 0 1 19.452149 45.526305 60.425823 60.425823 0 0 1-19.452149 45.112429z"
            fill="#A6C5F6" p-id="16665"></path>
          <path
            d="M993.355444 691.599204a86.913855 86.913855 0 0 0-4.138755-20.693775 87.32773 87.32773 0 0 0-18.624397-26.901908 73.669839 73.669839 0 0 0-22.349277-17.796646 56.700943 56.700943 0 0 0-24.418655-6.622008 33.11004 33.11004 0 0 0-26.901907 9.519136l-13.244016 11.174639a168.447328 168.447328 0 0 1-14.071767 12.416265l88.983232 86.086104a49.251184 49.251184 0 0 0 7.449759-6.208133l7.449759-7.863634 9.519137-9.519137a30.212911 30.212911 0 0 0 10.346887-23.590903zM732.200004 786.376693l-37.66267 37.248795-34.765542 34.351666-26.074157 25.660281-13.657891 13.244016a77.808594 77.808594 0 0 0-16.968896 23.177028 160.997569 160.997569 0 0 0-5.794257 15.727269l-7.863634 24.004779-7.449759 24.83253c-2.069377 8.27751-3.311004 14.485642-4.138755 18.210522a21.935401 21.935401 0 0 0 2.069377 17.382771c2.897128 3.311004 8.27751 4.55263 16.968896 3.311004a190.38273 190.38273 0 0 0 18.624397-4.55263 228.873151 228.873151 0 0 0 26.488032-7.035884c9.105261-2.483253 17.796646-5.794257 26.901908-8.691385L690.812454 993.314443l12.416265-7.449759 11.588514-8.691386 11.174639-11.174638 24.418654-24.418655 34.765542-33.937791 37.66267-36.834919 101.813373-98.916244-88.155481-86.086104L732.200004 786.376693z"
            fill="#4E8CEE" p-id="16666"></path>
        </svg>
        <div class="mt-5">建议反馈</div>
      </div>

    </div> -->

    <modal-login :visible="loginVisible" />
  </div>


</template>
<script>
import NavTools from '@/components/NavTools.vue'
import NavFooter from '@/components/NavFooter.vue'
import ModalLogin from '@/layout/ModalLogin'
export default {
  components: {
    NavTools,
    ModalLogin,
    NavFooter
  },
  data() {
    return {
      loginVisible: false
    }
  }
}
</script>
<style lang="scss" scoped>
.float {
  position: fixed;
  right: 50px;
  top: 35%;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 8px -8px rgb(36 91 219 / 6%), 0 6px 12px rgb(36 91 219 / 4%), 0 8px 24px 8px rgb(36 91 219 / 4%);
  border-radius: 10px;

  .list-item {
    text-align: center;
    padding: 20px 5px;
    width: 80px;
    cursor: pointer;
  }
}
</style>