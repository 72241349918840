<template>
    <div>
        <div class="footer-box">
            <div class="footer-top-wrap">

                <div class="footer-wrap-pc">
                    <div class="copyright">
                        <a-image class="logo-footer" :preview="false" src="https://www.strshook.com/cdn/logo.png">
                        </a-image>

                    </div>
                    <div class="platform">
                        <div>
                            <div class="footer-sub-title">探索</div>
                            <div class="link-box">
                                <div class="link-click"><a href="https://www.bytedance.com/zh/" target="_blank"
                                        rel="noreferrer nofollow">我们的优势</a></div>
                                <div class="link-click"><a href="https://www.douyin.com/" target="_blank"
                                        rel="noreferrer nofollow">解决方案</a></div>
                                <div class="link-click"><a href="https://www.douyin.com/" target="_blank"
                                        rel="noreferrer nofollow">产品支持</a></div>
                                <div class="link-click"><a href="https://www.douyin.com/" target="_blank"
                                        rel="noreferrer nofollow">客户案例</a></div>


                            </div>
                        </div>
                        <div>
                            <div class="footer-sub-title">产品</div>
                            <div class="link-box">
                             
                                <div class="link-click"><a href="#" target="_blank"
                                        rel="noreferrer nofollow">字符引力（微信小程序）</a></div>



                            </div>
                        </div>

                    </div>


                </div>
                
                <div class="right-qrcode">
                   <div>
                    <div class="footer-sub-title">关注或联系我们</div>
                    <div style="display: flex;">
                        <!-- <div class="qrcode-item">
                            <img width="88"
                                src="https://lf1-fe.ecombdstatic.com/obj/temai/ffa-external-download-app-qrcode.png" />
                            <span class="mt-10">扫码下载抖店APP</span>
                        </div> -->
                        <div class="qrcode-item">
                            <img width="88" src="https://www.strshook.com/cdn/qrcode_gzh.jpg" />
                            <span class="mt-10">我们的微信公众号</span>
                        </div>
                    </div>
                   </div>
                </div>

            </div>
            <div class="footer-bottom-wrap">

                <div class="copyright">© 2023 字符抖动
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45020402000201" target="_blank"
                        rel="noreferrer nofollow"><img :src="require('@/assets/icon/gwa.png')" class="icp">桂公网安备
                        45020402000201号</a>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer nofollow"><img
                            :src="require('@/assets/icon/icp.png')" class="icp">桂ICP备2023002340号-1</a>
                </div>

                <div class="other-footer-link">

                    <p> <a href="">营业执照</a> </p>
                    <p> <a href="">隐私声明</a> </p>
                </div>


            </div>
        </div>

    </div>
</template>
<style>
.logo-footer {
    text-align: center;

    width: 200px;
}

.logo-footer img {
    width: 60px !important;
}
</style>
<style lang="scss"  scoped>
$color: #9b9c9d;

.qrcode-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
    margin-top: 20px;

    span {
        color: #fff;
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        margin-top: 13px;
        opacity: .4;
    }
}

.footer-bottom-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .other-footer-link {
        display: flex;

        p {
            padding: 0 10px
        }

        a {
            color: $color;
        }
    }
}

.copyright {


    position: relative;

    color: $color;

    .icp {
        width: 15px;

        top: 3px;
        padding-right: 5px;
        position: relative;
    }


    a {
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
        margin: 0 5px;

        color: $color;
    }
}

.footer-box {
    padding: 0 220px 10px 220px;


    background-color: #252931;

    .footer-top-wrap {
        display: flex;
        padding: 40px 0;
        justify-content: space-between;
    }

    .footer-wrap-pc {

        display: flex;

        .platform {
            margin-left: 60px;
            position: relative;
            display: flex;

            ::after {

                content: '';
                display: block;
                height: 100%;
                border-right: 1px solid #34373b;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
    }

    .link-click {
        text-align: center;
        padding-top: 20px;

        a {
            color: #fff;
            opacity: .4;
            font-size: 14px
        }


    }



    .footer-sub-title {
        color: #fff;
        display: inline-block;
        flex-shrink: 0;

        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        opacity: .8;
        text-align: center;
        min-width: 150px;
    }
}
</style>