<template>
  <a-affix>
    <div class="header">

      <div class="menu-demo">


        <div class="logo">
          <a-image  :preview="false" src="https://www.strshook.com/cdn/logo.svg">
          </a-image>
        </div>

        <div class="menu-wrapper">

          <div class="menu-item">
            <h2 class="menu-item-link menu-item-link-active">
              <a href="">首页</a>
            </h2>
          </div>
          <div class="menu-item">
            <h2 class="menu-item-link">
              <a href="">短视频</a>
            </h2>
          </div>
          <div class="menu-item">
            <h2 class="menu-item-link">
              <a href="">资讯动态</a>
            </h2>
          </div>
          <div class="menu-item">
            <h2 class="menu-item-link">
              <a href="">软件定制开发</a>
            </h2>
          </div>
          <div class="menu-item">
            <h2 class="menu-item-link">
              <a href="">公司简介</a>
            </h2>
          </div>
          <div class="menu-item">
            <h2 class="menu-item-link">
              <a href="">加入我们</a>
            </h2>
          </div>

        </div>


        <!-- <div class="search">
          <a-space wrap>
            <div class="search-input">
              <a-input-search :style="{ width: '320px' }" size="large" placeholder="过去一年优质内容创作者数量增长484%,GMV翻一倍以上" />
            </div>
            <div class="search-form">
              <a-button type="text" size="large">

                <icon-apps size="25" />

              </a-button>
            </div>

          </a-space>


        </div> -->
      </div>

    </div>
  </a-affix>
</template>
<script>
export default {
  data() {
    return {

    }
  }
}
</script>
<style>
.logo img {
  height: 35px;
  padding-right: 50px;
}
</style>
<style lang="scss" scoped>

.menu-wrapper {
  display: flex;
  align-items: center;

  .menu-item {
    padding: 0 25px;

    .menu-item-link {
      position: relative;
    }

    a {
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      opacity: .85;
      transition: .5s;

      &:hover {
        color: aqua;
      }
    }

    .menu-item-link-active {
      a::after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        width: 24px;
        left: calc(50% - 12px);
        bottom: -8px;
        background-color: #ffffff;
        transition: opacity 0.3s ease-in-out;
      }


    }
  }
}

.menu-demo {
  display: flex;
  align-items: center;
  padding: 0 100px;
  height: 64px;
  justify-content: space-between;

}

.header {
  /* background-color: rgba(255, 255, 255, 0.8); */
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
  /* backdrop-filter: blur(34px); */

}


.search {
  min-width: 400px;
  text-align: right;
}
</style>