import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [

    {
        path: '/',
        name: 'home',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Home.vue')
    },
    {
        path: '/article/details/:id',
        name: 'article-details',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/article/details.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router